.chatroomContainer {
    position: relative; /* added for positioning context for chatInputSection */
    height: 500px; /* fixed height to ensure it doesn't grow */
    padding-bottom: 50px; /* space for the chatInputSection */
}

.chatLogs { /* new container for the chat logs */
    overflow-y: auto;
    max-height: 100px; /* considering the inputsection height */
    padding-bottom: 50px; 
    margin-left: 10px;
    margin-right: 10px;
}

.chatmessage2 {
    border-bottom: 1px solid #eaeaea;
    padding: 15px 10px;  /* Increase vertical padding */
    margin: 10px 0;     /* Increase vertical margin */
    background-color: #f4f4f4; 
    border-radius: 10px;
    position: relative;
}
.timestamp {
    font-size: 0.8em;
    color: #888;
    margin-bottom: 5px;
    margin-right: 10px;
}

.username {
    color: #333;
    font-weight: bold;
}

.messageContent {
    color: #444;
}
.chatInputSection {
    position: absolute;
    bottom: 0;

    width: 100%;
    height: 100px; /* fixed height for the input section */
    box-sizing: border-box; /* ensures padding doesn't add extra height */
    background-color: #fff;
}
.fullWidthButton {
    width: 100%;
    margin-bottom:50px;
}

.player, .playerPlaceholder {
    display: inline-block;
    margin: 10px;
  }
  
  .playerName {
    display: block;
    margin: 10px;
    padding: 5px;
    background: #e0e0e0; /* Light gray background for readability */
    border-radius: 5px;
    text-align: center;
  }
  
  .playerNamePlaceholder {
    display: block;
    margin: 10px;
    padding: 5px;
    background: #f0f0f0; /* Lighter gray for placeholder */
    border-radius: 5px;
    text-align: center;
    opacity: 0.6; /* Make it semi-transparent */
  }
  .playerName, .playerNamePlaceholder {
    display: block;
    text-align: center;
  }
  
  .playerPlaceholder {
    opacity: 0.6; /* Make it semi-transparent */
  }